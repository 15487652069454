import { Action, Reducer } from 'redux';
import { actionTypes } from './../../store/ActionTypes';
import { NotificationAction } from './../../store/common/NotificationStore';
import {
    PagedBatchStoreState,
    initialPagedBatchStoreState,
    RecievePagedOrganizerBatchAction,
    ClearPagedBatchAction
} from './../models/OrganizerStoreModels';

type KnownActions =
    RecievePagedOrganizerBatchAction |
    ClearPagedBatchAction |
    NotificationAction;

export const reducer: Reducer<PagedBatchStoreState> = (state: PagedBatchStoreState = initialPagedBatchStoreState, incomingAction: Action) => {
    const action = incomingAction as KnownActions;
    switch (action.type) {
        case actionTypes.RECEIVE_PAGED_ORGANIZER_BATCHES: {
            const pagedBatches = { ...state };
            pagedBatches.batches[action.query] = action.batchModel;
            pagedBatches.query = action.query;
            return pagedBatches;
        }
        case actionTypes.CLEAR_PAGED_BATCHES: {
            return { ...initialPagedBatchStoreState, query: "", batches: {} };
        }
    }
    return state || initialPagedBatchStoreState;
}